/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PARSE from "html-react-parser";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import TruncateMarkup from "react-truncate-markup";
import Api from "./Api";
import BaseArrowLinkTw from "../../context/components/Base/BaseArrowLinkTw";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";
import replaceAssetLink from "../../context/utils/replaceAssetLink";
import { FaFile } from "react-icons/fa6";

export default function TBSPlantation() {
  const [sections, setSections] = useState([]);
  const [widgets, setWidgets] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    const sectionsData = response.data.results;
    const sectionsWidgets = sectionsData[0]?.widgets;

    setSections(sectionsData);
    setWidgets(sectionsWidgets);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let title;
  let summary;
  let content;
  let media;
  let sectionName;
  let path;
  let textLink;
  if (sections) {
    sectionName = sections[0]?.slug_name;

    if (widgets && widgets.length !== 0) {
      widgets.forEach((section, i) => {
        switch (i) {
          case 0:
            const article = section.widget_contents[0];
            const {
              title: articleTitle,
              summary: articleSummary,
              content: articleContent,
              path: articlePath,
            } = article?.article_language || {};

            title = articleTitle;
            summary = articleSummary;
            content = articleContent;
            path = articlePath;
            media = replaceAssetLink(
              article?.assets?.[0]?.file_medium ||
                article?.assets?.[0]?.asset_file
            );

            break;
          case 1:
            // const assets = section.widget_contents;
            // media = assets;
            break;
          case 2:
            break;
          default:
            break;
        }
      });
    }
  }

  const doc = new DOMParser().parseFromString(content, "text/html");

  const tagsToRemove = "img";
  for (const elm of doc.querySelectorAll("*")) {
    if (elm.matches(tagsToRemove)) {
      elm.replaceWith(
        `<img src='${elm.src}' alt='${title}' height='692' width='auto' />`
      );
    }
  }

  console.log("🚀 ~ TBSRegulerArticle ~ media:", media);

  // Create a URL object
  const parsedUrl = media ? new URL(media) : null;

  // Get the filename by extracting the last part of the path
  const filename = parsedUrl?.pathname.split("/").pop();

  return (
    <BaseContainerPl
      sectionName={sectionName}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
    >
      {sections.length > 0 ? (
        <div className="section-width">
          <h2 className="heading-title"> {title}</h2>
          <h3 className="heading-summary"> {summary}</h3>
          <div className="margin-content">
            <div className="">
              {media && <img src={media} alt="" className=" mb-3 w-full" />}
            </div>
            {content && (
              <article className="typo-style prose-h2:flex prose-h2:items-center">
                {PARSE(
                  doc.body.innerHTML.replace(/&lt;/g, "<").replace(/&gt;/g, ">")
                )}
              </article>
            )}
          </div>
          <div className="mt-[2rem] font-inter-600 flex space-x-1 items-center ">
            <p className="">Attachment:</p>
            <a
              href={media}
              target="_blank"
              className="bg-gray-200 rounded-xl px-4 hover:bg-[#004A98] hover:text-white flex space-x-1 items-center"
            >
              <FaFile />
              <span className="">{filename}</span>
            </a>
          </div>
        </div>
      ) : (
        <Box sx={{ pt: 0.5 }} className="section-width">
          <Skeleton width="60%" />
          <Skeleton />
          <Skeleton variant="rectangular" width="100%" height={118} />
        </Box>
      )}
    </BaseContainerPl>
  );
}
