/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, AccordionSummary, AccordionDetails, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import PARSE from 'html-react-parser';
import moment from 'moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Api from './Api';
import Styles from './TBS_Reports_Accordion.module.css';
import BaseContainerPl from '../../context/components/Base/BaseContainerPl';
import replaceAssetLink from '../../context/utils/replaceAssetLink';

const CustomAccordion = styled((props) => <Accordion disableGutters elevation={0} square {...props} />)(() => ({
  borderRadius: '0',
  '&::before': { backgroundColor: 'transparent!important' },
  '&:last-of-type': { borderRadius: '0' },
}));

const CustomAccordionSummary = styled((props) => <AccordionSummary expandIcon={<ArrowDropDownIcon style={{ color: 'black' }} />} {...props} />)(() => ({
  backgroundColor: '#F4F4F4',
  minHeight: '0',
  padding: '0 10px 0 0',
  '& .MuiAccordionSummary-content': { margin: '0' },
  '& .MuiTypography-root': { width: '200px' },
  '& .MuiAccordion-root:before': { height: '0' },
}));

function TBS_Reports_Accordion() {
  const [section, setSection] = useState([]);
  const [slugName, setSlugName] = useState('');
  const [tableHead, setTableHead] = useState([]);
  const activeLang = useSelector((state) => state.content.activeLanguage);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const api = new Api();
        const sectionResponse = await api.getSectionDetail({ language: activeLang });
        const sectionData = sectionResponse.data.results[0];
        const { widgets, slug_name: sectionSlugName } = sectionData;
  
        setSection(widgets);
        setSlugName(sectionSlugName);
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, [activeLang]);

  let title1;
  let title2;
  let meetingTable;
  let datas;
  const listData = [];
  const sortingDate = [];
  const getYears = [];
  const tableTitle = [];
  
  if (section) {
    const getYear = [];
  
    section.forEach((data, index) => {
      const { widget_contents } = data;
  
      if (index === 0) {
        [title1, title2] = widget_contents.map((value) => value.content);
      }
  
      if (index >= 1 && index <= section.length - 3) {
        widget_contents.forEach((data) => {
          const year = new Date(data?.document_date).getFullYear();
          getYear.push(year);
        });
  
        sortingDate.push(
          widget_contents
            .sort((a, b) => Date.parse(new Date(a?.document_date)) - Date.parse(new Date(b?.document_date)))
            .reverse()
        );
      }
  
      if (index === section.length - 2) {        
        listData.push(...widget_contents.map((data) => data.content));
      }
  
      if (index === section.length - 1) {
        tableTitle.push(...widget_contents.map((value) => value.content));
      }
    });
  
    const arr = [...new Set(getYear)];
    getYears.push(...arr.sort().reverse());
  }
  


  return (
    <BaseContainerPl sectionName={slugName} sectionIndex={section && section.length > 0 && section[0].index}>
      <div className="section-width space-y-4">
        <div className={Styles.title}>
          <h2 className="heading-title">{title1}</h2>
          <h3 className="heading-sub-title">{title2}</h3>
        </div>
        <div className="flex flex-col gap-y-8">
          {getYears &&
            getYears?.map((val, idx) => {
              return (
                <CustomAccordion defaultExpanded={idx === 0 ? true : false} key={val.id}>
                  <CustomAccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                    <div className="flex gap-x-6">
                      <div className="w-[10px] h-[53px] bg-[#004A98]" />
                      <Typography className={Styles.Accordion_title}>{val}</Typography>
                    </div>
                  </CustomAccordionSummary>
                  <AccordionDetails sx={{ padding: '8px 0px 0px' }}>
                    <table id="customers" className="table-auto w-full">
                      <thead>
                        <th className="border-2 border-white px-4 py-2 text-white bg-[#18467f] font-medium">{tableTitle[0]}</th>
                        <th className="border-2 border-white px-4 py-2 text-white bg-[#18467f] font-medium">{tableTitle[1]}</th>
                      </thead>
                      <tbody className="border-2 border-white px-4 py-2">
                        {listData &&
                          listData?.map((listName, listid) => {
                            let idxVal = 0;
                            return (
                              sortingDate &&
                              sortingDate.map((datas, ids) => {
                                if (listid === ids) {
                                  idxVal = idxVal + 1;
                                  const grouped = _.groupBy(datas, 'document_date');
                                  return Object.entries(grouped).map(([key, value], objId) => {
                                    const ContentDate = new Date(key).getFullYear();
                                    if (ContentDate === val) {
                                      idxVal = idxVal + 1;
                                      const isRender = idxVal === 2;
                                      
                                      return (
                                        <tr key={listid} className="border-2 border-white px-4 py-2 bg-[#F2F5F6]">
                                          <td className={`w-[120px] text-left px-4 border-2 border-white  py-2 font-medium ${Styles['data-text']}`}>
                                            {moment(key).format('DD MMMM YYYY')}
                                          </td>
                                          <td className={`w-[200px] text-left px-4 border-2 border-white  py-2 ${Styles['data-text']} ${objId}`}>
                                            {isRender && <h4 className="font-bold pb-2">{listName}</h4>}
                                            <ul className="list-disc pl-4">
                                              {value?.map((data) => {
                                                return (
                                                  <li className="underline underline-offset-4">
                                                    <a href={replaceAssetLink(data.file)} target="_blank" rel="noreferrer">
                                                      {data.description}
                                                    </a>
                                                  </li>
                                                );
                                              })}
                                            </ul>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  });
                                }
                              })
                            );
                          })}
                      </tbody>
                    </table>
                  </AccordionDetails>
                </CustomAccordion>
              );
            })}
        </div>
      </div>
    </BaseContainerPl>
  );
}

export default TBS_Reports_Accordion;
